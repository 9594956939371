import React from 'react'
import styled from 'styled-components'

const Content = styled.div`
  max-width: 1110px;
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
`

const FooterComponent = styled.div`
  width: 100%;
  color: #b2b2b2;
  font-family: 'Helvetica Neue';
  background: #fff;
  font-size: 14px;
  line-height: 20px;
`

const NavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const NavItem = styled.li`
  display: inline-block;
  margin-left: 12px;
`

const SocialIcon = styled.a`
  width: 16px;
  height: 16px;
  display: inline-block;
`

const SocialIconImage = styled.svg`
  fill: #b2b2b2;
  &:hover {
    fill: #777;
  }
`

const Footer = () => (
  <FooterComponent>
    <Content>
      <div>&copy; 2019 Create Design Systems by Garth Braithwaite.</div>
      <nav>
        <NavList>
          <NavItem>
            <SocialIcon href="https://twitter.com/garthdb">
              <SocialIconImage
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124-4.09-.193-7.715-2.157-10.141-5.126-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z" />
              </SocialIconImage>
            </SocialIcon>
          </NavItem>
          <NavItem>
            <SocialIcon href="https://github.com/garthdb">
              <SocialIconImage
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577l-.015-2.04c-3.338.724-4.042-1.61-4.042-1.61-.546-1.385-1.335-1.755-1.335-1.755-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57 4.801-1.574 8.236-6.074 8.236-11.369 0-6.627-5.373-12-12-12" />
              </SocialIconImage>
            </SocialIcon>
          </NavItem>
          <NavItem>
            <SocialIcon href="https://dribbble.com/garthdb">
              <SocialIconImage
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12 24c-6.615 0-12-5.385-12-12s5.385-12 12-12 12 5.385 12 12-5.385 12-12 12zm10.12-10.358c-.35-.11-3.17-.953-6.384-.438 1.34 3.684 1.887 6.684 1.992 7.308 2.3-1.555 3.936-4.02 4.395-6.87zm-6.115 7.808c-.153-.9-.75-4.032-2.19-7.77l-.066.02c-5.79 2.015-7.86 6.025-8.04 6.4 1.73 1.358 3.92 2.166 6.29 2.166 1.42 0 2.77-.29 4-.814zm-11.62-2.58c.232-.4 3.045-5.055 8.332-6.765l.405-.12c-.26-.585-.54-1.167-.832-1.74-5.12 1.53-10.084 1.465-10.534 1.455l-.004.312c0 2.633.998 5.037 2.634 6.855zm-2.42-8.955c.46.008 4.683.026 9.477-1.248-1.698-3.018-3.53-5.558-3.8-5.928-2.868 1.35-5.01 3.99-5.676 7.17zm7.635-7.863c.282.38 2.145 2.914 3.822 6 3.645-1.365 5.19-3.44 5.373-3.702-1.81-1.61-4.19-2.586-6.795-2.586-.825 0-1.63.1-2.4.285zm10.335 3.483c-.218.29-1.935 2.493-5.724 4.04.24.49.47.985.68 1.486l.22.53c3.41-.43 6.8.26 7.14.33-.02-2.42-.88-4.64-2.31-6.38z" />
              </SocialIconImage>
            </SocialIcon>
          </NavItem>
        </NavList>
      </nav>
    </Content>
  </FooterComponent>
)

export default Footer
