import React from 'react'
import styled from 'styled-components'

const StyledFigure = styled.figure`
  margin: 0;
`

const Figure = ({ src, alt }) => (
  <StyledFigure>
    <img src={src} alt={alt} />
    <figcaption>{alt}</figcaption>
  </StyledFigure>
)

export default Figure
