import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import MDXRenderer from 'gatsby-mdx/mdx-renderer'
import Layout from '../components/layout'
import styled from 'styled-components'
import DateTime from '../components/datetime'
import Figure from '../components/post/figure'

const CategoryList = ({ list = [] }) => (
  <Fragment>
    Categories:
    <ul>
      {list.map(category => (
        <li key={category}>
          <a href={`/categories/${category}`}>{category}</a>
        </li>
      ))}
    </ul>
  </Fragment>
)

const PostHeader = styled.header``

const PostDescription = styled.div`
  color: #333;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 56px;
`

const PostTitle = styled.h1`
  font-size: 40px;
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height: 48px;
  margin: 11px 0 16px;
`

const PostContainer = styled.article`
  max-width: 730px;
  margin: 0 auto;
  padding-top: 143px;
`

const Dateline = styled.div`
  font-size: 14px;
`

const PostSubHeading = styled.h2`
  font-size: 24px;
  margin-bottom: 12px;
  letter-spacing: -0.1px;
  line-height: 32px;
`

const PostParagraph = styled.p`
  font-size: 18px;
  line-height: 28px;
`

export default function Post({
  data: { site, mdx },
  pageContext: { next, prev },
}) {
  return (
    <Layout>
      <PostContainer>
        <PostHeader>
          <Dateline>
            <DateTime datetime={mdx.frontmatter.date} /> • Garth Braithwaite
          </Dateline>
          <PostTitle>{mdx.frontmatter.title}</PostTitle>
          <PostDescription>{mdx.frontmatter.description}</PostDescription>
        </PostHeader>

        <MDXRenderer
          components={{
            // Map HTML element tag to React component
            h2: PostSubHeading,
            p: PostParagraph,
            img: Figure
          }}
        >
          {mdx.code.body}
        </MDXRenderer>

        <div>
          <CategoryList list={mdx.frontmatter.categories} />

          <hr />

          {prev && (
            <span>
              Previous <a href={prev.fields.slug}>{prev.fields.title}</a>
            </span>
          )}
          {next && (
            <span>
              Next <a href={next.fields.slug}>{next.fields.title}</a>
            </span>
          )}
        </div>
      </PostContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { id: { eq: $id } }) {
      frontmatter {
        title
        description
        date(formatString: "")
        slug
        categories
        keywords
      }
      code {
        body
      }
    }
  }
`
